import loadSurvey from '../utils/loadSurvey';
import Container from './container';
import modal from './modal';
import speechBubble from './speechBubble';

const CONTAINER_TYPE = {
  modal,
  speechBubble,
};

export default class PopupLayer extends Container {
  constructor(dist) {
    super(dist);
    this.unique = this.dist.opts.containerType === 'modal';
    this.showWhenLoaded = this.dist.opts.trigger === 'none';
  }

  show() {
    if (!this.el) {
      const { container, content, minHeight } = CONTAINER_TYPE[this.dist.opts.containerType](this.dist);
      this.el = container;

      document.body.appendChild(container);

      document.body.addEventListener('keydown', e => {
        if (e.key === 'Escape') {
          this.hide();
        }
      });

      this.promise = loadSurvey(content, this.dist, true, minHeight, this.showWhenLoaded);
    }

    if (this.showWhenLoaded && !this.dist.preview) {
      this.promise.then(() => {
        this.el.classList.add('open');
      });
    } else {
      setTimeout(() => {
        this.el.classList.add('open');
      });
    }

    return this.promise;
  }

  hide() {
    if (this.el) {
      this.el.classList.remove('open');
    }
  }

  isActive() {
    return this.el && this.el.className.match(/open/);
  }
}
