import xtend from 'xtend';
import getTrigger from './triggers';
import getContainer from './containers';
import { resetTimeTracker } from './utils/timeTracker';
import { setPersistentData } from './storage';
import { compileCustomExpression } from './utils/customExpression';

const defaults = {
  id: undefined,
  surveyId: undefined,
  surveyUrl: undefined,
  includedUrls: [],
  excludedUrls: [],
  excludedIPs: [],
  groupSurveyIds: [],
  popupWidth: 520,
  popupHeight: 480,
  delay: 120,
  sessionDelay: 60,
  idle: 600,
  frequency: 90 * 24 * 60 * 60, // 90 days in seconds
  declineFrequency: 30 * 24 * 60 * 60, // 30 days in seconds
  portion: 100,
  elementSelector: false,
  trigger: 'none',
  container: 'popupWindow',
  containerType: 'modal',
  triggerStyle: 'tab',
  triggerPosition: 'centerRight',
  buttonIcon: 'comments',
  buttonLabel: 'Feedback',
  deviceTypes: [],
};

const noTimer = {
  delay: 0,
  sessionDelay: 0,
  idle: 0,
  frequency: 0,
  declineFrequency: 0,
};

const containerDefaults = {
  popupWindow: {
    trigger: 'invisibleLayer',
  },
  newWindow: {
    trigger: 'invisibleLayer',
  },
  popupLayer: {
    popupHeight: 150,
  },
  embed: noTimer,
};

const triggerDefaults = {
  button: noTimer,
  existingButton: noTimer,
};

export default class Distribution {
  constructor(opts) {
    this.opts = xtend(defaults, containerDefaults[opts.container || defaults.container]);
    this.opts = xtend(this.opts, triggerDefaults[opts.trigger || this.opts.trigger], opts);

    this.trigger = getTrigger(this);
    this.container = getContainer(this);

    this.compiledCustomExpression = compileCustomExpression(this);
  }

  shouldTrigger(validationResults, preview = false) {
    if (preview) {
      this.preview = true;
    } else {
      this.validationResults = validationResults;
    }

    if (validationResults === true) {
      if (!this.trigger.isActive()) {
        if (setPersistentData(this.opts.surveyId, { triggered: new Date().getTime() })) {
          this.trigger.show();
        } else {
          this.log('Could not set persistent data');
        }
      }
    } else if (this.trigger.isActive() && !this.container.isActive() && !this.preview) {
      this.trigger.hide();
    }
  }

  shouldTrackTime() {
    return this.opts.delay > 0;
  }

  shouldOpen() {
    if (!this.container.isActive()) {
      if (setPersistentData(this.opts.surveyId, { shown: new Date().getTime() })) {
        this.container.show().then(() => {
          this.log('Container shown');
        }).catch((e) => {
          this.log('Container could not be shown.', e.message);
        });
      }
    } else {
      this.log('Container already open');
    }
  }

  close() {
    this.container.hide();

    setPersistentData(this.opts.surveyId, {
      closed: new Date().getTime(),
    });

    this.preview = false;

    this.log('Closed');
  }

  hide() {
    this.trigger.hide();
  }

  answerLater() {
    this.container.hide();

    resetTimeTracker();

    this.log('Answer later');
  }

  responseSaved() {
    setPersistentData(this.opts.surveyId, {
      responded: new Date().getTime(),
    });

    this.log('Response saved');
  }

  log(...args) {
    window.extellio.log(`[${this.opts.surveyTitle}]`, this.opts.id, ...args);
  }
}
